<template lang="pug">
  header.header
    .header__container
      a(
        href="#"
        v-scroll-to="'#steps1'"
        @click="isMenuOpen = false"
      ).header__logo.logo
        img(src="@/assets/images/logo.svg" alt="logo")
      .header__menu.menu
        button(
          type="button"
          :class="{ 'menu-open': isMenuOpen }"
          @click="isMenuOpen = !isMenuOpen"
        ).menu__icon.icon-menu
          span
        nav(
          :class="{ 'menu-open': isMenuOpen }"
          @click="isMenuOpen = !isMenuOpen"
        ).menu__body
          ul.menu__list
            li.menu__item(:class="{ active: activeSection === 'steps1' }")
              a.menu__link(href="#", @click.prevent="scrollToSection('steps1')") Головна
            li.menu__item(:class="{ active: activeSection === 'steps2' }")
              a.menu__link(href="#", @click.prevent="scrollToSection('steps2')") Про Drivelink
            li.menu__item(:class="{ active: activeSection === 'steps3' }")
              a.menu__link(href="#", @click.prevent="scrollToSection('steps3')") Характеристики
            li.menu__item(:class="{ active: activeSection === 'steps4' }")
              a.menu__link(href="#", @click.prevent="scrollToSection('steps4')") Ціни
            li.menu__item(:class="{ active: activeSection === 'steps5' }")
              a.menu__link(href="#", @click.prevent="scrollToSection('steps5')") Контакти
            li.menu__item
              a.link.menu__link.menu__link--civil(href="https://zsu.drivelink.com.ua/") ДЛЯ ВІЙСЬКОВИХ
</template>

<script>
import VueScrollTo from 'vue-scrollto'
export default {
  name: 'HeaderBlock',
  data () {
    return {
      isScrolled: false,
      activeSection: 'steps1',
      isMenuOpen: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    scrollToSection (sectionId) {
      VueScrollTo.scrollTo(`#${sectionId}`, 500, { offset: -86 })
      this.isMenuOpen = true
    },
    handleScroll () {
      const sections = document.querySelectorAll('section')
      let currentSection = ''
      sections.forEach(section => {
        const sectionTop = section.offsetTop
        const sectionHeight = section.clientHeight
        if (window.pageYOffset >= sectionTop - (sectionHeight / 7) - 22) {
          currentSection = section.getAttribute('id')
        }
      })
      if (currentSection !== this.activeSection) {
        this.activeSection = currentSection
      }
      const header = document.querySelector('.header')
      if (window.pageYOffset > 50) {
        header.classList.add('scrolled')
      } else {
        header.classList.remove('scrolled')
      }
    }
  }
}
</script>
